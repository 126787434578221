import React from 'react';

function index() {
  return (
    <div>
      <h1>Request a Demo</h1>
    </div>
  );
}

export default index;
